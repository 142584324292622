<script>

	import Multiselect from "vue-multiselect"
	import datetime from 'vuejs-datetimepicker'
	import Layout from "../../layouts/main"
	import PageHeader from "@/components/page-header"
	import appConfig from "@/app.config"

	import {
		required
	} from "vuelidate/lib/validators";


	/**
	 * Advanced-form component
	 */
	export default {
		page: {
			title: "Attendance Edit Form",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Multiselect,
			Layout,
			PageHeader,
			datetime,
		},
		data() {
			return {
				title: "Attendance Edit Form",
				items: [
					{
						text: "Restaurant Brand",
						href: "/",
					},
					{
						text: "Add",
						active: true,
					},
				],
				value: null,
				status: '',
				submitted: false,
				attendanceID: 0,
				isEdit: false,
				moment: this.$moment,
				alertMsg: "Attendance Updated Successfully!",
				disabled: true,
				disabledOwner: false,
				showDismissibleAlert: false,
				loading: false,
				attendanceData: [],
				posts: {
					employeeName: "",
					shift: "",
					inTime: "",
					outTime: "",
					inLatitude: "",
					inLongitude: "",
					outLatitude: "",
					outLongitude: "",
					colorCode: '#000000',
					empType: false,
				}
			};
		},
		mounted() {
			// if (this.$route.params.type == 'Edit') {
			// 	if (this.$route.params.id) {
			// 		sessionStorage.setItem('attendanceID', this.$route.params.id);
			// 	}
			// } else {
			// 	sessionStorage.setItem('attendanceID', 1);
			// }
			// this.attendanceID = sessionStorage.getItem('attendanceID')
			this.attendanceID = this.$route.params.attendanceId
			this.getAttendanceID()
		},
		validations: {
			posts: {
				inTime: {
					required,
				},
				outTime: {
					required,
				},
				inLatitude: {
					required,
				},
				inLongitude: {
					required,
				},
				outLatitude: {
					required,
				},
				outLongitude: {
					required,
				},
			},

		},
		methods: {
			changeColor(color) {
				const { rgba: { r, g, b, a } } = color;
				this.posts.colorCode = `rgba(${r, g, b, a})`;
			},
			submitEditAttendanceForm: function() {
				// alert(this.posts.delTime);
				this.submitted = true;
				// stop here if form is invalid
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					this.loading = true
					let apiName = "admin/update-attendance-detail"
					let routerPush = "attendance";
					// if (this.isEdit == true) {
					// 	apiName = "updateAttendance";
					// 	this.alertMsg = "Attendance Updated Successfully!"
					// 	routerPush = "attendance";
					// }
					this.axios.post(apiName, {
						employeeAttendanceId: this.attendanceID,
						inDateTime: this.posts.inTime,
						outDateTime: this.posts.outTime,
						inCoordinatesLatitude: this.posts.inLatitude,
						inCoordinatesLongitude: this.posts.inLongitude,
						outCoordinatesLatitude: this.posts.outLatitude,
						outCoordinatesLongitude: this.posts.outLongitude,
					}).then((result) => {
						console.log(result.data)
						// this.status = result.data.data
						// this.showDismissibleAlert = true
						this.loading = false
						let status = result.data.data.responseCode
						if (status === 200) {
							setTimeout(() => {
								this.$router.push({ name: routerPush })
							}, 2000)
							this.$toast.success('Attendance is updated', {
								position: "top-right",
								timeout: 5000,
								closeOnClick: true,
								pauseOnFocusLoss: true,
								pauseOnHover: true,
								draggable: true,
								draggablePercent: 0.6,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: "button",
								icon: true,
								rtl: false
							})
						}
						if (status === 400) {
							this.$toast.error(result.data.response, {
								position: "top-right",
								timeout: 5000,
								closeOnClick: true,
								pauseOnFocusLoss: true,
								pauseOnHover: true,
								draggable: true,
								draggablePercent: 0.6,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: "button",
								icon: true,
								rtl: false
							})
						}
					}).catch((error) => {
						this.loading = false
						//console.error(error.response)
						alert(error.response.data.data)
					})
				}
			},
			getAttendanceID: function() {
				this.axios.post("admin/attendance-detail", {
					employeeAttendanceId: this.attendanceID,
				}).then((result) => {
					this.isReportTableBusy = false
					this.attendanceData = result.data.data
					let attendanceData = result.data.data.employeeAttendanceDetail
					this.posts.employeeName = result.data.data.employeeDetail.name
					this.posts.shift = result.data.data.corporateShiftDetail.shiftName
					this.posts.inTime = this.moment(attendanceData.inDateTime).format('YYYY-MM-DD HH:mm:ss')
					console.log(attendanceData.outDateTime)
					if (attendanceData.outDateTime !== null) {
						this.posts.outTime = this.moment(attendanceData.outDateTime).format('YYYY-MM-DD HH:mm:ss')
					} else {
						this.posts.outTime = this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
						console.log(this.posts.outTime)
					}
					console.log(this.posts.outTime)
					this.posts.inLatitude = attendanceData.inCoordinates.coordinates[1]
					this.posts.inLongitude = attendanceData.inCoordinates.coordinates[0]
					this.posts.outLatitude = attendanceData.outCoordinates.coordinates[1]
					this.posts.outLongitude = attendanceData.outCoordinates.coordinates[0]
				});
			},
		},
		middleware: "authentication",
	};
	// 
</script>
<!-- SPECIFIC CSS -->
<style scoped src="../../../assets/css/listing.css"></style>
<style scoped src="../../../assets/css/detail-page.css"></style>
<style lang="scss">
	.datetime-picker #tj-datetime-input {
		border: 0 none;
		outline: 0 none;
		padding: 0;
		box-shadow: 0 none;
	}
</style>
<template lang="en">
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="alertMsg">
			<b-alert v-model="showDismissibleAlert" variant="success" dismissible>
				{{alertMsg}}
			</b-alert>
		</div>
		<form @submit.prevent="submitEditAttendanceForm" method="post">
			<div class="row">
				<div class="col-lg-12">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="mb-3 col-md-6">
									<label class="form-label" for="formrow-employeeName-input">Employee Name </label>
									<input type="text" class="form-control" disabled id="formrow-employeeName-input" name="employeeName" v-model="posts.employeeName" />
								</div>
								<div class="mb-3 col-md-6">
									<label>Shift</label>
									<input type="text" class="form-control" disabled id="formrow-shift-input" name="shift" v-model="posts.shift" />
								</div>
							</div>
							<div class="row">
								<div class="col-md-6 mb-3">
									<label class="form-label" for="formrow-inTime">In Time</label>
									<div class="input-group">
										<datetime class="form-control" format="YYYY-MM-DD H:i:s" v-model="posts.inTime" name="inTime" :class="{
											'is-invalid': submitted && $v.posts.inTime.$error,
										}" ></datetime>
										<div v-if="submitted && $v.posts.inTime.$error" class="invalid-feedback">
											<span v-if="!$v.posts.inTime.required">This value is required.</span>
										</div>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<label class="form-label" for="formrow-outTime">Out Time</label>
									<div class="input-group">
										<datetime class="form-control" format="YYYY-MM-DD H:i:s" v-model='posts.outTime' name="outTime"></datetime>
										<div v-if="submitted && $v.posts.outTime.$error" class="invalid-feedback">
											<span v-if="!$v.posts.outTime.required">This value is required.</span>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6 mb-3">
									<label class="mt-3">In Latitude*</label>
									<input type="text" class="form-control" id="formrow-inLatitude-input" step="any"
										v-model="posts.inLatitude" name="inLatitude" :class="{
										'is-invalid': submitted && $v.posts.inLatitude.$error,
									}">
									<div v-if="submitted && $v.posts.inLatitude.$error" class="invalid-feedback">
										<span v-if="!$v.posts.inLatitude.required">This value is required.</span>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<label class="mt-3">In Longitude*</label>
									<input type="text" class="form-control" name="inLongitude" id="formrow-inLongitude-input" step="any"
										v-model="posts.inLongitude" :class="{
										'is-invalid': submitted && $v.posts.inLongitude.$error,
									}">
									<div v-if="submitted && $v.posts.inLongitude.$error" class="invalid-feedback">
										<span v-if="!$v.posts.inLongitude.required">This value is required.</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6 mb-3">
									<label class="mt-3">Out Latitude*</label>
									<input type="text" class="form-control" step="any" id="formrow-outLatitude-input" v-model="posts.outLatitude" name="outLatitude"
										:class="{
										'is-invalid': submitted && $v.posts.outLatitude.$error,
									}">
									<div v-if="submitted && $v.posts.outLatitude.$error" class="invalid-feedback">
										<span v-if="!$v.posts.outLatitude.required">This value is required.</span>
									</div>
								</div>
								<div class="col-md-6 mb-3">
									<label class="mt-3">Out Longitude*</label>
									<input type="text" class="form-control" step="any" id="formrow-outLongitude-input" name="outLongitude"  v-model="posts.outLongitude"
										:class="{
										'is-invalid': submitted && $v.posts.outLongitude.$error,
									}">
									<div v-if="submitted && $v.posts.outLongitude.$error" class="invalid-feedback">
										<span v-if="!$v.posts.outLongitude.required">This value is required.</span>
									</div>
								</div>
							</div>
						</div>
						<button type="submit" class="col-3 m-2 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Update</button>
					</div>
					</div>
				</div>
			</div>
		</form>

	</Layout>
</template>